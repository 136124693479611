import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import Relation from '../images/services/rationalizaion.jpg'
import Network from '../images/services/networking.jpg'
import Support from '../images/services/support.jpg'
import Security from '../images/services/security.jpg'
import Management from '../images/services/management.jpg'
import Performance from '../images/services/performance.jpg'
import Backup from '../images/services/backup.jpg'
import Virtualization from '../images/services/virtualization.jpg' 
import Consulting from '../images/services/consulting.jpg'
import Cloud from '../images/services/cloud.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import Consulting1 from '../images/services/consulting.jpg'
import Implementation from '../images/services/implementation.jpeg'
import Applications from '../images/services/applications.jpg'
import { StaticImage } from "gatsby-plugin-image"

const SolutionsPage = () => {
    return (
        <Layout pageTitle="Pricing">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                        <div className="scroll-down-wrap">
                    <span>sentinel@pragicts.com</span>
                        <span>+94 11 70 4598 983</span>
                     
                    </div>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2><span> Pricing</span></h2>
                      
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                   
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Pricing <span>//</span></div>
            <div className="container">
                <div >
              
                    <p>Sentinel is a bespoke solution fully customized for the requirements of each client. Therefore contact PragICTS at <a href="mailto:sentinel@pragicts.com">sentinel@pragicts.com</a> or <a href="tel:+94 1 70 459 8983">+94 1 70 459 8983</a> for scoping and pricing.</p>
                    
                </div>
               
                  

                    
                    

           
            </div>
            <div className="sec-lines"></div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage
